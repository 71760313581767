<template>
  <div class="container">
    <div v-for="(item, index) of types" :key="item.name" class="typesWarp">
      <img class="cover" :src="imgList[index]" alt="" />
      <div class="right">
        <div class="typeTitle">{{ item.type }}</div>
        <div v-for="ii in item.list" :key="ii.name" class="listWarp">
          <div class="title" @click="jumpDetails(ii.id)">{{ ii.name }}</div>
        </div>
        <div class="more" @click="jumpList(item.type)">更多 &gt;</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/legalResearchResult"
export default {
  name: 'ResearchResultTypes',
  components: {
    API
  },
  data() {
    return {
      // getType: 'getExpertInterview',
      imgList: [
        require('@/assets/researchResult/gjwl.png'),
        require('@/assets/researchResult/xdmy.png'),
        require('@/assets/researchResult/sxtz.png'),
        require('@/assets/researchResult/slwh.png'),
      ],
      types: []
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      API.getLegalResearchResultTypes().then(res => {
        this.types = res?.content
      })
    },
    jumpList(name) {
      name && this.$router.push({
        path: `/researchResult/list?type=${name}`,
      })
    },
    jumpDetails(id) {
      id && this.$router.push({
        path: `/researchResult/details?id=${id}`,
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.container {
  display: flex;
  justify-content: space-between;
  width: @uni-width;
  min-height: calc(100vh - 334px);
  margin: 45px auto;
  min-width: 1200px;
  flex-wrap: wrap;
  .typesWarp{
    width: 520px;
    height: 220px;
    background: #FFFFFF;
    box-shadow: 0px 0px 35px 0px rgba(176,176,176,0.27);
    padding: 30px;
    margin-bottom: 40px;
    display: flex;

    .cover{
      width: 260px;
      height: 220px;
      margin-right: 20px;
    }
    .right{

      .typeTitle{
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .listWarp{

        .title{
          font-size: 14px;
          color: #666666;
          padding: 8px 0 ;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 260px;
        }
      }
      .more{
        font-size: 14px;
        color: #00A4FF;
        margin-top: 16px;
      }
    }
  }
}
</style>
